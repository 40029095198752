.leaflet-container {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .leaflet-tooltip.compact {
    padding: 0 3px;
  }

  .fa-icon-inside-leaflet-icon-holder {
    position: absolute;
    width: 100%;
    left: 50%;
    margin-left: -50%;
    top: 4px;
    text-align: center;
    font-size: 90%;
  }

  .circular-leaflet-marker-icon {
    height: 14px;
    width: 14px;
    border-radius: 14px;
    border: 1px solid rgba(255, 255, 255, 0.75);
  }
}

// preventing some defaults on L.divIcon
.leaflet-div-icon {
  background-color: transparent !important;
  border: 0 !important;
}

a.leaflet-control-layers-toggle {
  background-size: 30px 30px;
}

.leaflet-touch {
  a.leaflet-control-layers-toggle {
    background-size: 36px 36px;
  }
}

// default canvas z-index is 100 which makes svg outline overlap canvas hotline
.leaflet-map-pane {
  canvas,
  svg {
    z-index: 200;
  }
}

.leaflet-marker-wiki-icon {
  background-color: white;
  border-radius: 9px;
  border: 1px solid black;

  & > * {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    left: 0;
    right: 0;
  }
}

@media (max-width: 720px) {
  .leaflet-control-scale {
    margin-bottom: 4rem !important;
  }
}

@media (max-width: 271px) {
  .leaflet-control-scale {
    margin-bottom: 7.5rem !important;
  }
}

.fm-base-layer .fm-demo-tile {
  box-shadow: 5px 5px 8px #0004;
}

.fm-nonpremium-tile {
  background-color: #aaa8;
  paint-order: stroke fill;
  -webkit-text-stroke: 2px #fff;
  color: #000a;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    transform: rotate(45deg);
    max-width: 200px;
    text-align: center;
  }
}
